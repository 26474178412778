<template>
  <div>
    <v-card style="padding: 10px">
      <div class="v-card__title">{{ translate('Adjust Inventory') }}</div>
      <div class="v-card__text">
        <v-form v-model="first_form" ref="first_form" lazy-validation class="v-form multi-col-validation">
          <div class="row">
            <div class="col-md-6 col-12">
              <label class="text-center text-uppercase text--primary bold">{{ translate('Note') }}</label>

              <v-text-field
                :rules="emptyRule"
                v-model="note"
                :label="translate('Note')"
                clear-icon="shrink"
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="col-md-6 col-12">
              <label class="text-center text-uppercase text--primary bold">{{ translate('Date') }}</label>

              <div
                class="
                  v-input v-input--hide-details v-input--dense
                  theme--light
                  v-text-field
                  v-text-field--is-booted
                  v-text-field--enclosed
                  v-text-field--outlined
                  v-text-field--placeholder
                "
              >
                <div class="v-input__control">
                  <div class="v-input__slot">
                    <fieldset aria-hidden="true">
                      <legend style="width: 0px"><span class="notranslate">​</span></legend>
                    </fieldset>

                    <v-datetime-picker class="outlined" v-model="date" @input="menu2"> </v-datetime-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <label class="text-center text-uppercase text--primary bold">{{ translate('Magazine') }}</label>

              <v-select
                :rules="emptyRule"
                outlined
                dense
                @input="name => magazine_selected(name)"
                :items="magazines.map(a => a.name)"
              ></v-select>
            </div>

            <div></div>
          </div>
        </v-form>
      </div>

      <v-form v-model="second_form" ref="second_form" lazy-validation>
        <v-card class="mx-auto mt-10" outlined>
          <div data-v-6fcca708="" class="d-flex justify-space-between flex-wrap pt-12">
            <div data-v-6fcca708="" class="me-2 mb-2">
              <div data-v-6fcca708="" class="v-card__title pt-0 px-0">{{ translate('Stocks') }}</div>
            </div>
            <button
              data-v-6fcca708=""
              v-on:click="
                stocks.push({
                  image_url: '',
                  id: Math.random() * 10000,
                  product: products[0],
                  amount: 0,
                  type: 'Remove',
                })
              "
              type="button"
              class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
            >
              <span class="v-btn__content">{{ translate('add') }}</span>
            </button>
          </div>
          <v-simple-table height="auto" max-height="500" fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center text-uppercase">{{ translate('Image') }}</th>
                  <th class="text-center text-uppercase">{{ translate('Product') }}</th>
                  <th class="text-center text-uppercase">{{ translate('Amount') }}</th>
                  <th class="text-center text-uppercase">{{ translate('Adjust Type') }}</th>
                  <th class="text-center text-uppercase">{{ translate('Actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="stock in stocks" :key="stock.id">
                  <td class="text-center" style="padding: 10px">
                    <div
                      style="
                        overflow: visible;
                        position: relative;
                        min-width: 110px;
                        max-width: 110px;
                        min-height: 110px;
                        max-height: 110px;
                        margin: 5px;
                        padding: 5px;
                        border-style: dashed;
                      "
                    >
                      <a :href="stock.image_url" target="_blank"
                        ><v-img style="height: 95px; width: 100%" :src="stock.image_url"></v-img
                      ></a>
                    </div>
                  </td>
                  <td class="text-center" style="padding: 10px">
                    <v-select
                      :rules="emptyRule"
                      dense
                      outlined
                      class="shrink"
                      @input="id => product_selected(stock, id)"
                      :items="products.map(a => a.name)"
                    ></v-select>
                  </td>

                  <td class="text-center" style="padding: 10px">
                    <v-text-field
                      :rules="emptyRule"
                      label="Amount"
                      v-model="stock['product_amount']"
                      :suffix="stock.product.measurement_name"
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </td>

                  <td class="text-center" style="padding: 10px">
                    <v-select
                      class="shrinked"
                      outlined
                      dense
                      v-model="stock['type']"
                      :items="['Add', 'Remove']"
                    ></v-select>
                  </td>

                  <td class="text-center">
                    <div
                      v-on:click="stocks = stocks.filter(item => item !== stock)"
                      class="v-avatar elevation-1 rounded error"
                      style="height: 30px; min-width: 30px; width: 30px"
                    >
                      <span
                        aria-hidden="true"
                        class="v-icon notranslate theme--dark white--text"
                        style="font-size: 25px; height: 25px; width: 25px"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          role="img"
                          aria-hidden="true"
                          class="v-icon__svg"
                          style="font-size: 25px; height: 25px; width: 25px"
                        >
                          <path :d="icons.mdiCloseCircleOutline"></path></svg
                      ></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-form>
      <br />
      <v-divider></v-divider>
      <div class="d-flex justify-end flex-wrap" style="padding-left: 10px">
        <v-btn color="success" style="margin: 10px" v-on:click="submit"> {{ translate('Submit') }} </v-btn>
      </div>
    </v-card>
  </div>
</template>
  <script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { mdiPlus, mdiCloseCircleOutline } from '@mdi/js'
import { adjustInventory, getCurrency, getMagazines, getProducts, getSuppliers, newCheckin } from '@/ApiManager'

export default {
  methods: {
    turnStockToString(stock) {
      return `${stock.id}${stock.amount}${stock.product.id}${stock.quantity_price}${stock.tax}${stock.discount}`
    },
    calculateTotal(stock) {
      var price = 0
      //get total price
      price = stock.amount * stock.quantity_price
      //calculate discount
      price = price - (price * stock.discount) / 100
      //calculate tax
      price = price + (price * stock.tax) / 100
      return price
    },
    product_selected(stock, name) {
      for (var i = 0; i < this.products.length; i++) {
        if (this.products[i]['name'] == name) {
          stock['product'] = this.products[i]
          stock['product_id'] = this.products[i].product_id
          stock['measurement_name'] = this.products[i].measurement_name
          stock['product_amount'] = this.products[i].product_amount
          stock['amount'] = 1
          stock['magazine_id'] = this.products[i].magazine_id
          stock['quantity_price'] = this.products[i].buy_price
          stock['image_url'] = this.products[i].image_url
        }
      }
    },
    supplier_selected: function (supplier_name) {
      for (var i = 0; i < this.suppliers.length; i++) {
        if (this.suppliers[i]['name'] == supplier_name) {
          this.selected_supplier = this.suppliers[i]['id']
          return
        }
      }
    },
    magazine_selected: function (magazine_name) {
      for (var i = 0; i < this.magazines.length; i++) {
        if (this.magazines[i]['name'] == magazine_name) {
          this.selected_magazine = this.magazines[i]['id']
          return
        }
      }
    },
    new_raw_material: function (_) {
      this.raw_materials.push({ key: this.key, raw_material_id: 0, amount: 0 })
      this.key += 1
    },
    cancel: function (_) {
      this.$router.go(-1)
    },
    submit: async function (_) {
      this.$refs.first_form.validate()
      this.$refs.second_form.validate()

      if (this.$refs.first_form.validate() == false || this.$refs.second_form.validate() == false) {
        return
      }
      console.log(JSON.stringify(this.stocks))
      this.$store.state.loading = true
      var date = `${this.date.getFullYear()}/${('0' + (this.date.getMonth() + 1)).slice(-2)}/${(
        '0' + this.date.getDate()
      ).slice(-2)} ${this.date.getHours()}:${this.date.getMinutes()}:${this.date.getSeconds()}`

      await adjustInventory(parseInt(this.selected_magazine), this.note, date, JSON.stringify(this.stocks))
      this.$router.push({ name: 'stocks' })
    },
  },
  computed: {},
  created() {
    getProducts().then(response => {
      this.products = response.filter(item => item.type == 'single')
    })
    getMagazines().then(response => {
      for (var i = 0; i < response.length; i++) {
        var product = {
          id: response[i]['id'],
          name: response[i]['name'],
        }
        this.magazines.push(product)
      }
    })
    getCurrency().then(response => {
      this.currency = response['currency']
    })
  },
  data() {
    return {
      first_form: null,
      second_form: null,
      note: '',
      date: new Date(),
      menu2: false,
      products: [],
      //this is the available raw material that the user can choose from
      magazines: [],
      //this list contains the raw materials that the user have chosen
      selected_magazine: 0,
      stocks: [],
      currency: '',
      key: 0,
      emptyRule: [v => !!v || "Can't be empty!"],
    }
  },
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiCloseCircleOutline,
      },
    }
  },
}
</script>
  <style >
@import url('https://unpkg.com/leaflet@1.6.0/dist/leaflet.css');
.v-input.shrinked .v-select__selections input {
  display: none;
  width: 5px;
}
.bold {
  color: black;
  font-family: sans-serif;
  font-weight: 700;
}
.component-container {
  display: table;
  @media (pointer: none), (pointer: coarse) {
    width: calc(100% - (50%));
  }
}
.component-row {
  display: table-row;
}
.component-row div {
  position: relative;
  display: table-cell;
}
.component-row div:nth-child(2) {
  left: 20px;
}
.component-row div:nth-child(3) {
  left: 40px;
}
</style>